$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");
        $("body").toggleClass("freeze");

        if ($(".js-nav").get(0).classList.contains("active")) {
            $(".js-aside").css("color", "#fff");
        }
        else {
            $(".js-aside").css("color", "");
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * jumptop
    // *
    // *
    $(".js-jumptop").click(function () {
        if ($(".js-jump-anchor").length) {
            $(".js-jump-anchor").get(0).scrollIntoView({ behavior: 'smooth' })
        }
        else {
            $(window).scrollTop(0);
        }
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav jump down
    // *
    // *
    $(".js-jumpdown").click(function () {
        $(".js-nav-ul li:last-child").get(0).scrollIntoView({ behavior: 'smooth' });
    })

    var nav = $(".js-nav-ul").get(0);
    var hasVerticalScrollbar = nav.scrollHeight > nav.clientHeight;
    if (hasVerticalScrollbar) {
        $(".js-jumpdown").removeClass("hide")
    } else {
        $(".js-jumpdown").addClass("hide");
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * reviewSlider
    // *
    // *
    new Swiper('.js-swiper-review', {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 120,
        // autoHeight: true,
        centeredSlides: true,


        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: false
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 60
            }
        }

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * iconSlide
    // *
    // *
    new Swiper('.js-swiper-icon', {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 90,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 25,
                pagination: false
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 60
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 90
            }
        }

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * galleryslider
    // *
    // *
    new Swiper('.js-swiper-gallery', {
        loop: true,
        centeredSlides: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            // dynamicBullets: true,
            // dynamicMainBullets: 3
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.4,
                spaceBetween: 0,
                pagination: false
            },
            768: {
                slidesPerView: 1.7,
                spaceBetween: 50
            },
            1440: {
                slidesPerView: 2,
                spaceBetween: 90
            }
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * galleryslider
    // *
    // *
    new Swiper('.js-swiper-team', {
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 100,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    $(".swiper:not(.js-swiper-team)").each(function () {
        let slideCount = $(this).find(".swiper-slide").length;
        let paginationBulletWidth = window.innerWidth > 768 ? (slideCount / 2) * 3.7 : (slideCount / 2) * 3.3;
        let buttonWidth = window.innerWidth > 768 ? 2.7 : 2;
        if (window.innerWidth > 768) {
            $(".swiper-button-prev").get(0).style.setProperty("--swiper-navigation-sides-offset", `calc(50% -  ${paginationBulletWidth}rem - ${buttonWidth}rem - 2rem) `);
            $(".swiper-button-next").get(0).style.setProperty("--swiper-navigation-sides-offset", `calc(50% -  ${paginationBulletWidth}rem - ${buttonWidth}rem - 2rem) `);
        }
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-header").click(function () {
        $(this).parents(".js-accordion-item").toggleClass("active");
        $(this).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
        $(this).siblings(".js-accordion-content").slideToggle();
        setTimeout(() => {
            $(this).get(0).scrollIntoView({ behavior: 'smooth' })
        }, 400);
    });

    let hash = window.location.hash.split('#')[1];
    if ($("#" + hash).hasClass("js-accordion-item") && !$("#" + hash).hasClass("open")) {
        $("#" + hash).find(".js-accordion-header").click();
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * flipup
    // *
    // *
    if (matchMedia('(pointer:coarse)').matches) {
        $(".js-flipup").click(function () {
            $(this).toggleClass("active").focus()
        })
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hero tooltip
    // *
    // *
    $(".js-tooltip-src").click(function () {
        $(this).parents(".js-tooltip-parent").addClass("active");
        $(this).parents(".js-tooltip-parent").siblings().removeClass("active")
        $(this).parents(".js-tooltip-parent").siblings().addClass("hide");
    })

    $(".js-herohome-picture").click(function () {
        console.log("hallo")
        $(this).siblings(".js-herohome-list").find(".js-tooltip-parent").removeClass("active");
        $(this).siblings(".js-herohome-list").find(".js-tooltip-parent").removeClass("hide");
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textgrid headline
    // *
    // *
    if (window.innerWidth > 1000) {
        $(".js-textgrid").each(function () {
            var elements = $(this).find(":header");
            var maxHeight = Math.max(...elements.map(function () {
                return $(this).height();
            }).get());
            elements.height(maxHeight);
        })
    }

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * colored aside
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= window.innerHeight / 2) {
        $(".js-aside").addClass("colored");
    } else {
        $(".js-aside").removeClass("colored");
    }
    if ($(window).scrollTop() >= 30) {
        $(".js-jumptop").addClass("visible");
    } else {
        $(".js-jumptop").removeClass("visible");
    }
    if ($(window).scrollTop() >= $(".js-footer").position().top - window.innerHeight / 2) {
        $(".js-aside").addClass("noBlendMode");
    }
    else {
        $(".js-aside").removeClass("noBlendMode");
    }
});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// *  clone
// *
// *
$(window).on("load resize", function () {

    if (window.innerWidth <= 768) {
        $(".js-hero-source").find(".js-hero-clone-content").each(function () {
            $(".js-hero-target").append($(this))
        })
    }
    if (window.innerWidth <= 1000) {
        var parent = $(".js-accordion-image-clone-parent");
        parent.each(function () {
            var src = $(this).find(".js-accordion-image-clone-src");
            var target = $(this).find(".js-accordion-image-clone-target");
            target.append(src);
        })


    }
})

// $(window).on('hashchange', function () {
// let hash = window.location.hash.split('#')[1];
// if (!$("#" + hash).hasClass("open")) {
//     $("#" + hash).find(".js-accordion-header").click();
// }
// })